export type Language = string;

export const originalLanguage = {
  value: '', // empty string means original language
  flag: 'globe',
  label: 'Original',
} as const;

export const clientLanguages = [
  { value: 'de', flag: 'de', label: 'Deutsch' },
  { value: 'en', flag: 'gb', label: 'English' },
  { value: 'fr-CH', flag: 'fr', label: 'Français' },
  { value: 'it-CH', flag: 'it', label: 'Italiano' },
  { value: 'pl', flag: 'pl', label: 'Polski' }, // polish
  { value: 'cz', flag: 'cz', label: 'Česky' }, // czech
  { value: 'sk', flag: 'sk', label: 'Slovenský' }, // slovakian
  { value: 'ru', flag: 'ru', label: 'Русский' }, // russian
  { value: 'hu', flag: 'hu', label: 'Magyar' }, // hungarian
  { value: 'ro', flag: 'ro', label: 'Română' }, // romanian
  { value: 'nl', flag: 'nl', label: 'Nederlands' }, // dutch
  { value: 'si', flag: 'si', label: 'Slovenščina' }, // slovenian
  { value: 'bg', flag: 'bg', label: 'български' }, // Bulgarian
  { value: 'ar', flag: 'sa', label: 'العربية' }, // Arabic
] as const;

export const supportedLanguages = clientLanguages.map((language) => language.value);

export type LanguageCode = typeof clientLanguages[number]['value'] | '';

export const deeplLanguages = [
  'bg', 'cs',    'da',    'de',
  'el', 'en-GB', 'en-US', 'es',
  'et', 'fi',    'fr',    'hu',
  'id', 'it',    'ja',    'ko',
  'lt', 'lv',    'nb',    'nl',
  'pl', 'pt-BR', 'pt-PT', 'ro',
  'ru', 'sk',    'sl',    'sv',
  'tr', 'uk',    'zh',    'zh-HANS',
];

/**
 * Maps our language codes to deepl language codes.
 */
export const mapLanguageCodeToDeepl = (lng: LanguageCode) => {
  switch (lng) {
    case 'cz':
      return 'cs';
    case 'en':
      return 'en-US';
    case 'fr-CH':
      return 'fr';
    case 'it-CH':
      return 'it';
    case 'si':
      return 'sl';
    default:
      return lng;
  }
};

// used to set the language for AI requests
export const clientLanguagesEnglish = [
  { value: 'original', label: 'Original' },
  { value: 'de', label: 'German' },
  { value: 'en', label: 'English' },
  { value: 'fr-CH', label: 'French' },
  { value: 'it-CH', label: 'Italian' },
  { value: 'pl', label: 'Polish' },
  { value: 'cz', label: 'Czech' },
  { value: 'sk', label: 'Slovak' },
  { value: 'ru', label: 'Russian' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'ro', label: 'Romanian' },
  { value: 'nl', label: 'Dutch' },
  { value: 'si', label: 'Slovenian' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'ar', label: 'Arabic' },
] as const;

export const getLanguageLabel = (value: LanguageCode): string => {
  if (value === '') return originalLanguage.label;

  const language = clientLanguages.find((language) => language.value === value);
  return language?.label || '';
};

export const getEnglishLanguageLabel = (value?: LanguageCode): string => {
  const language = clientLanguagesEnglish.find((language) => language.value === value);
  return language?.label || 'source language';
};

/**
 * Client Only!
 *
 * Used to set the content language for all requests.
 */
export const setContentLanguage = (language?: LanguageCode) => {
  if (typeof window !== 'undefined') {
    if (language && supportedLanguages.includes(language)) {
      window.localStorage.setItem('language', language);
    } else {
      window.localStorage.removeItem('language');
    }
  }
};

export const getContentLanguage = (): LanguageCode => {
  if (typeof window !== 'undefined') {
    const lng = window.localStorage.getItem('language') as LanguageCode;

    if (!lng || !supportedLanguages.includes(lng)) return '';

    return lng;
  }

  return '';
};
